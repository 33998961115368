module.exports = {
  black: "#000",
  blue: {
    50: "#eef6f7",
    100: "#e0f2f5",
    200: "#b8e5f0",
    300: "#92d3ec",
    400: "#81c1e3",
    500: "#74adda",
    600: "#6596cb",
    700: "#5a82b9",
    800: "#476a9c",
    900: "#36537f",
  },
  green: {
    50: "#f4fcf9",
    100: "#dcf8eb",
    200: "#b9e7c7",
    300: "#a9d798",
    400: "#91c676",
    500: "#80b65e",
    600: "#74ab53",
    700: "#5c9343",
    800: "#4b7a3a",
    900: "#345828",
  },
  neutral: {
    50: "#f8f8f8",
    100: "#f1f1f2",
    200: "#e8e8ea",
    300: "#d1d0d6",
    400: "#bbb9c1",
    500: "#9a97a4",
    600: "#6d697e",
    700: "#443f57",
    800: "#2f2a43",
    900: "#1c1831",
  },
  orange: {
    50: "#f9efed",
    100: "#f5e3df",
    200: "#facec3",
    300: "#ffb9a5",
    400: "#fba78b",
    500: "#f89a73",
    600: "#f88f62",
    700: "#e77849",
    800: "#be582d",
    900: "#842c06",
  },
  pink: {
    50: "#f5eef3",
    100: "#f5e0ef",
    200: "#f2b1d2",
    300: "#ef84a5",
    400: "#e06986",
    500: "#d55d79",
    600: "#cb536f",
    700: "#c04965",
    800: "#a6344d",
    900: "#8a243b",
  },
  purple: {
    50: "#f3eefd",
    100: "#eae1fb",
    200: "#e1cef9",
    300: "#c8a6ee",
    400: "#b188e0",
    500: "#936cd2",
    600: "#7656c0",
    700: "#5c42a4",
    800: "#4b388b",
    900: "#44377b",
  },
  "status-green": {
    50: "#f1f7f1",
    200: "#d3ebca",
    500: "#75ac7c",
    600: "#4E8655",
    700: "#235c2b",
  },
  "status-red": {
    50: "#fcf3f3",
    500: "#E37474",
    600: "#BD5050",
    700: "#800808",
  },
  "status-teal": {
    50: "#f3fbfb",
    100: "#D9F3F4",
    300: "#8ACDD1",
    500: "#32A3AA",
    600: "#00737A",
    700: "#125153",
  },
  "status-yellow": {
    50: "#fef7f1",
    500: "#e4a14f",
    600: "#B77422",
    700: "#854200",
  },
  "status-info": {
    100: "#D1D8FF",
    200: "#a9c6ff",
  },
  "accent-blue": {
    50: "#EEF6F7",
    100: "#EDEFFF",
    200: "#C5E7F8",
    400: "#85C8EC",
    500: "#74ADDA",
  },
  "accent-purple": {
    200: "#EAD2FF",
    400: "#C79BFA",
  },
  white: "#fff",
};
