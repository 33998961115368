import CareType, {
  CoachingCareTypes,
  TherapyCareTypes,
} from "@olivahealth/graphql-server/src/domain/value-objects/CareType";
import cx from "classnames";
import Image from "../../../../../apps/web-client/src/components/common/Image";
import tokens from "../../../theme/tokens";
import Button from "../../atoms/Button";
import Card from "../../atoms/Card";
import Tag from "../../atoms/Tag";
import Text from "../../atoms/Text";
import {
  ArrowIcon,
  ClockIcon,
  ExclamationIcon,
  UserPlaceholderIcon,
} from "../../atoms/Icons";

export interface EventCardProfessionalData {
  image: string | null;
  name: string;
  title: string | null;
}

interface Props {
  applyBackgroundMask?: boolean;
  awaitingPayment?: string | null;
  buttonLabel: string;
  careType: CareType | null;
  date: string;
  duration?: string;
  eventTypeTranslation?: string;
  id: string;
  imageSource?: string;
  isActive?: string;
  onClickButton?: () => void;
  pendingProgressCheckSurvey: string | null;
  professional?: EventCardProfessionalData;
  refunded?: string;
  showEventTypeAsTag: boolean;
  title?: string;
}

export default function EventCardV2({
  applyBackgroundMask,
  awaitingPayment,
  buttonLabel,
  careType,
  date,
  duration,
  eventTypeTranslation,
  imageSource,
  isActive,
  onClickButton,
  pendingProgressCheckSurvey,
  professional,
  refunded,
  showEventTypeAsTag,
  title,
}: Readonly<Props>) {
  const backgroundImage = imageSource ? `url('${imageSource}')` : undefined;
  const textColor = isActive ? "white" : "primary";

  return (
    <Card
      animateOnHover
      className={cx("relative rounded-xl bg-cover", {
        "bg-accent-blue-200": careType && CoachingCareTypes.includes(careType),
        "bg-accent-purple-200": careType && TherapyCareTypes.includes(careType),
      })}
      style={{
        backgroundImage,
      }}
    >
      {applyBackgroundMask && (
        <div className="absolute inset-0 bg-white bg-opacity-50 rounded-xl" />
      )}
      <div className="h-[16.25rem] lg:h-[20rem] p-4 flex flex-col relative z-10">
        <div className="flex flex-col justify-between">
          <div className="flex gap-1 mb-2">
            {showEventTypeAsTag && (
              <Tag
                theme="dark"
                variant={
                  careType
                    ? TherapyCareTypes.includes(careType)
                      ? "therapy"
                      : "professionalCoaching"
                    : "purple"
                }
                weight="bold"
              >
                {eventTypeTranslation}
              </Tag>
            )}
            {isActive && (
              <Tag variant="popular" weight="bold">
                {isActive}
              </Tag>
            )}
            {pendingProgressCheckSurvey && (
              <Tag
                variant="negative"
                weight="bold"
                theme={isActive ? "light" : "dark"}
              >
                <span className="flex gap-x-2">
                  <ExclamationIcon size={20} variant="triangle" />
                  {pendingProgressCheckSurvey}
                </span>
              </Tag>
            )}
            {awaitingPayment && (
              <Tag variant="negative" weight="bold">
                {awaitingPayment}
              </Tag>
            )}
            {refunded && (
              <Tag variant="negative" weight="bold">
                {refunded}
              </Tag>
            )}
          </div>
          <Text color={textColor} weight="bold" component="p">
            {date}
          </Text>
          <div className="flex items-center gap-1">
            <ClockIcon size={14} color={tokens.colors["neutral"]["700"]} />
            <Text color="primary-light" size="sm" weight="medium">
              {duration}
            </Text>
          </div>
        </div>
        <div className="flex flex-col mt-auto">
          <Text color={textColor} weight="bold" gutter="md">
            <span className="block overflow-hidden whitespace-nowrap text-ellipsis text-base lg:text-2xl">
              {title || eventTypeTranslation}
            </span>
          </Text>
          {professional && (
            <div className="flex gap-2 items-center mb-4">
              {professional.image ? (
                <Image
                  src={professional.image}
                  alt="professional image"
                  className="rounded-full object-cover"
                  width={48}
                  height={48}
                />
              ) : (
                <UserPlaceholderIcon
                  size={48}
                  color={isActive ? "white" : tokens.colors["neutral"][900]}
                />
              )}
              <Text color="primary-light" weight="bold">
                {professional.name}
              </Text>
            </div>
          )}
        </div>
        <div>
          <Button
            width="full"
            variant={isActive ? "primaryInverse" : "secondary"}
            iconRight={
              isActive ? <ArrowIcon direction="right" size={24} /> : undefined
            }
            onClick={onClickButton}
          >
            {buttonLabel}
          </Button>
        </div>
      </div>
    </Card>
  );
}
